import website from "../assets/website.png";
import monitor from "../assets/monitor.png";
import home from "../assets/home.png";
// import it from "../assets/it.png";
import insurance from "../assets/insurance.png";
import backend from "../assets/backend.png";
import internet from "../assets/internet.png";
import group from "../assets/group.png";
import tprs from "../assets/tprs.svg";
// import claim from "../assets/claim.png";
import agent from "../assets/agent.png";
// import kruz from "../assets/kruz.jpg";

export const apps = [
  {
    name: "Agency Portal",
    icon: agent,
    link: "https://portal.donewelllife.com.gh",
    content: "Agency Portal for Donewell Life agents",
  },
  // Commenting it out temporarily
  // {
  //   name: "Request a Claim",
  //   icon: claim,
  //   link: "https://claims.donewelllife.com.gh",
  //   content: "make a claim request",
  // },
  {
    name: "Claims Portal",
    icon: website,
    link: "https://claimcenter.pinnaclelife.com.gh",
    content: "claims processing and reporting portal",
  },
  // {
  //   name: "Old Kruz",
  //   icon: website,
  //   link: "https://kruz.pinnaclelife.com.gh:6364/kruz/",
  //   content: "Requisitions, Memos and stocks portal",
  // },
  {
    name: "Kruz",
    icon: website,
    link: "https://dlkruz.pinnaclelife.com.gh",
    content: "Requisitions, Memos and stocks portal",
  },
  {
    name: "Dashboard",
    icon: monitor,
    link: "https://app.pinnaclelife.com.gh/",
    content: "Dashboard",
  },
  {
    name: "Mobi Dashboard",
    icon: home,
    link: "https://mobidashboard.pinnaclelife.com.gh:7777/login.aspx",
    content: "Mobidashboard",
  },
  // {
  //   name: "IT Support",
  //   icon: it,
  //   link: "https://itsupport.donewelllife.com.gh:444/pro_users/login",
  //   content: "IT support for Donewell Life",
  // },
  {
    name: "Signup for new policy",
    icon: insurance,
    link: "https://pinnaclelife.com.gh/products/",
    content: "Link to policy signup forms",
  },
  {
    name: "LMS",
    icon: internet,
    link: "http://turnquest.donewelllife.com.gh:9003/lmslive",
    content: "TurnQuest LMS",
  },
  {
    name: "CRM",
    icon: internet,
    link: "http://turnquest.donewelllife.com.gh:9005/crmlive",
    content: "TurnQuest CRM",
  },
  {
    name: "FMS",
    icon: internet,
    link: "http://turnquest.donewelllife.com.gh:9004/fmslive",
    content: "TurnQuest FMS",
  },
  {
    name: "Group",
    icon: group,
    link: "http://turnquest.donewelllife.com.gh:9003/grouplive",
    content: "TurnQuest LMS Group",
  },
  {
    name: "Equipments",
    icon: backend,
    link: "https://pinnaclelife.com.gh/equipment/",
    content: "Oracle DB console",
  },
  {
    name: "TPRS",
    icon: tprs,
    link: "https://gogtprs.com/index.php?action=login",
    content: "TurnQuest FMS",
  },
];
